import { service } from "@/ajax/request";
import { AxiosPromise } from "axios";

export const watchConfigList = (params): AxiosPromise =>{
    return service({
        url: `/watch-config/list`,
        method: "POST",
        params,
      });
}

export const getBindRecords = (serialNo): AxiosPromise =>{
    return service({
        url: `/watch-config/binding-records/${serialNo}`,
        method: "POST",
      });
}

